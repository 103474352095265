import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  useSoyuzBrandConfig,
  getPagePath,
  trans
} from '@spotahome/soyuz/client';

import Metrics from '@spotahome/soyuz-tracking';
import { Autocompleter, Button } from '@spotahome/ui-library';
import {
  extractItem,
  setItem
} from '@spotahome/ui-library/src/utils/localStorage';

import MagnifyingGlassIcon from '@spotahome/landings-common/src/components/MagnifyingGlassIcon';

import { FEATURED_CITIES } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { showFeedbackToast } from './CitySuggestionToast';
import './CityAutocompleter.scss';

const CITY_KEY = 'sah-search-city';
const prevSearchedCity = extractItem(CITY_KEY);

const getInitialCityName = (cities, brandConfig) => {
  const brandCity = brandConfig.mainCity;
  if (!brandCity) {
    return prevSearchedCity || '';
  }

  return cities.find(city => city.id === brandCity)?.cityName || '';
};

const CityAutocompleter = ({
  cities,
  className = '',
  deviceType,
  iconColor = ''
}) => {
  const brandConfig = useSoyuzBrandConfig();
  const [city, setCity] = useState('');
  const autocompleterRef = useRef(null);

  const isMobileOrTablet = deviceType !== 'desktop';

  useEffect(() => {
    setCity(getInitialCityName(cities, brandConfig));
  }, [cities]);

  const shakeAutocompleterWrapper = () => {
    const EXECUTE_AFTER_MS = 1000;
    autocompleterRef.current.classList.toggle('city-selector--shaking');
    setTimeout(() => {
      autocompleterRef.current.classList.toggle('city-selector--shaking');
    }, EXECUTE_AFTER_MS);
  };

  const getCities = () =>
    cities.reduce(
      (accumulator, currentCity) => ({
        ...accumulator,
        [currentCity.cityName]: {
          cityId: currentCity.id,
          countryId: currentCity.country?.countryId
        }
      }),
      {}
    );

  const cityList = getCities();

  const sendCitySelectedAction = cityId => {
    Metrics.actions.sendActionEvent(Metrics.actions.SELECT_CITY, {
      cityId,
      pageType: 'homepage'
    });
  };

  const navigateToSearchedCity = searchedCity => {
    const { cityId, countryId } = cityList[searchedCity];
    const locationString = `${cityId}--${countryId}`;
    const desiredCityUrl = getPagePath('realestate.free-search', {
      params: { locationString }
    });

    SoyuzAnalytics.sendGA4Event('search', {
      search_term: cityId
    });
    window.location.href = desiredCityUrl;
  };

  const setCitySearchedHistory = searchedCity => {
    setItem(CITY_KEY, searchedCity);
  };

  const handleClickExploreButton = e => {
    e.preventDefault();

    if (!city) {
      shakeAutocompleterWrapper();
    }

    if (!cityList[city]) {
      return; // Note: users may type a city that we do not support, so we do not send them anywhere
    }

    setCitySearchedHistory(city);

    sendCitySelectedAction(cityList[city].cityId);
    navigateToSearchedCity(city);
  };

  const handleCityChange = desiredCity => setCity(desiredCity);

  const handleCitySelected = selectedCity => {
    setCity(selectedCity);

    sendCitySelectedAction(cityList[selectedCity].cityId);

    navigateToSearchedCity(selectedCity);
    setCitySearchedHistory(selectedCity);
  };

  const scrollIntoAutocompleter = () =>
    autocompleterRef.current.scrollIntoView({ behavior: 'smooth' });

  const handleAutocompleterClick = () => {
    if (isMobileOrTablet) {
      Metrics.hotjar.recordTag('HomepageCityAutocompleterMobile');

      setTimeout(scrollIntoAutocompleter, 500);
    } else {
      Metrics.hotjar.recordTag('HomepageCityAutocompleter');
    }
  };

  const handleSubmitSuggestion = () => {
    showFeedbackToast();
  };

  const citySelectorClasses = classNames('city-selector', className);

  return (
    <>
      <div className={citySelectorClasses} ref={autocompleterRef}>
        <Autocompleter
          initialValue={city}
          minLength={1}
          list={Object.keys(cityList)}
          featuredResults={cities
            .filter(cityItem => FEATURED_CITIES.includes(cityItem.id))
            .map(cityItem => cityItem.cityName)}
          placeholder={trans('autocompleter.placeholder.city')}
          typingPlaceholder={trans('autocompleter.placeholder.typing-hint')}
          className="city-selector__autocompleter"
          dataTest="city-selector-autocomplete"
          onSelectedElement={handleCitySelected}
          onChange={handleCityChange}
          onClick={handleAutocompleterClick}
          onSubmitSuggestion={handleSubmitSuggestion}
          selectAllContentOnFocus={!isMobileOrTablet}
          otherCitySuggestionsEnabled
          showIcon={isMobileOrTablet}
          newTheming={!isMobileOrTablet}
          iconColor={iconColor}
        />

        <Button
          type="button"
          fullWidth
          size="big"
          className="city-selector__new-button"
          data-test="city-selector-button"
          onClick={handleClickExploreButton}
        >
          <span className="city-selector__new-button-text">
            {isMobileOrTablet
              ? trans('landingpage.city_selector.button.search-city')
              : trans('landingpage.city_selector.button.where-to-live')}
          </span>
          <span className="city-selector__new-button-icon">
            <MagnifyingGlassIcon />
          </span>
        </Button>
      </div>
    </>
  );
};

CityAutocompleter.propTypes = {
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      country: PropTypes.shape({
        countryId: PropTypes.string
      })
    })
  ).isRequired,
  className: PropTypes.string,
  deviceType: PropTypes.oneOf(['smartphone', 'tablet', 'desktop']).isRequired,
  iconColor: PropTypes.string
};

export default CityAutocompleter;
