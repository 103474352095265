import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const SpotahomeFooter = props => {
  const spotahomeLinks = [
    {
      url: props.aboutUs,
      transKey: 'footer.item.about_us'
    },
    {
      url: props.blog,
      transKey: 'footer.item.blog'
    },
    {
      url: props.institutions,
      transKey: 'footer.item.institutions'
    },
    {
      url: props.companyDetails,
      transKey: 'footer.item.company_details'
    },
    {
      url: props.sitemap,
      transKey: 'footer.item.sitemap'
    }
  ];
  const NO_FOLLOW_PAGES = [props.companyDetails];

  return (
    <FooterBlock>
      <FooterBlockTitle text="Spotahome" />
      <FooterBlockList>
        {spotahomeLinks.map(({ transKey, url }) => (
          <FooterLink
            key={transKey}
            linkTheme={props.linkTheme}
            href={url}
            rel={NO_FOLLOW_PAGES.includes(url) ? 'nofollow' : ''}
          >
            {trans(transKey)}
          </FooterLink>
        ))}
      </FooterBlockList>
    </FooterBlock>
  );
};

SpotahomeFooter.propTypes = {
  aboutUs: PropTypes.string.isRequired,
  institutions: PropTypes.string.isRequired,
  sitemap: PropTypes.string.isRequired,
  companyDetails: PropTypes.string.isRequired,
  linkTheme: PropTypes.string.isRequired,
  blog: PropTypes.string.isRequired
};

export default SpotahomeFooter;
