import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const LegalFooter = props => {
  const spotahomeLinks = [
    {
      url: props.privacyPolicies,
      transKey: 'footer.item.privacy_policies'
    },
    {
      url: props.cookiesPolicy,
      transKey: 'footer.item.cookies_policy'
    },
    {
      url: props.termsAndConditions,
      transKey: 'footer.item.terms_and_conditions'
    }
  ];
  const NO_FOLLOW_PAGES = [props.privacyPolicies, props.cookiesPolicy];

  return (
    <FooterBlock>
      <FooterBlockTitle text={trans('footer.label.legal')} />
      <FooterBlockList>
        {spotahomeLinks.map(({ transKey, url }) => (
          <FooterLink
            key={transKey}
            linkTheme={props.linkTheme}
            href={url}
            rel={NO_FOLLOW_PAGES.includes(url) ? 'nofollow' : ''}
          >
            {trans(transKey)}
          </FooterLink>
        ))}
      </FooterBlockList>
    </FooterBlock>
  );
};

LegalFooter.propTypes = {
  cookiesPolicy: PropTypes.string.isRequired,
  privacyPolicies: PropTypes.string.isRequired,
  termsAndConditions: PropTypes.string.isRequired,
  linkTheme: PropTypes.string.isRequired
};

export default LegalFooter;
