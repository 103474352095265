import { memo } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { trans } from '@spotahome/soyuz-trans/client';

import IconGeneric from '../IconGeneric';

import styles from './LanguageSwitcher.module.scss';

const GA_CLASSNAME = 'ga-menu-language-switcher';

const themes = {
  dark: 'language-switcher__dark',
  light: 'language-switcher__light'
};

const LanguageSwitcher = ({
  locales,
  current,
  abbreviated = false,
  onChange = () => {},
  theme = 'default'
}) => {
  const supportedLocales = [...locales];
  const currentLocale = current;

  const setLocaleCookie = selectedLocale => {
    const cookies = new Cookies();
    const options = { path: '/', maxAge: 2628000 }; // expires after one month
    return cookies.set('locale', selectedLocale, options);
  };

  const handlerOnChange = event => {
    const selectedLocale = event.target.value;
    setLocaleCookie(selectedLocale);
    onChange(selectedLocale, current);
  };

  const generateClassNames = () => {
    const currentTheme = themes[theme];
    const themeClassName = styles[currentTheme] || '';
    const baseClassName = styles['language-switcher'];

    return `${baseClassName} ${themeClassName} ${GA_CLASSNAME}`;
  };

  return (
    <span className={generateClassNames()}>
      <span className={`${styles['language-switcher__menu']}`}>
        <span className={`${styles['language-switcher__menu-text']}`}>
          {abbreviated ? currentLocale : trans(`locale.${currentLocale}`)}
        </span>
        <IconGeneric name="angle-down" color="inherit" size="extra-small" />
      </span>
      <select
        defaultValue={currentLocale}
        className={`${styles['language-switcher__select']}`}
        onChange={handlerOnChange}
      >
        {supportedLocales.sort().map(locale => (
          <option key={locale} value={locale}>
            {trans(`locale.${locale}`)}
          </option>
        ))}
      </select>
    </span>
  );
};

LanguageSwitcher.propTypes = {
  abbreviated: PropTypes.bool,
  current: PropTypes.string.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(['default', 'dark', 'light'])
};

export default memo(LanguageSwitcher);
