import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';
import { trans } from '@spotahome/soyuz/client';
import classNames from 'classnames';
import { isBiggerThanPhablet } from '@spotahome/ui-library/src/tenant/components/utils/breakpoints';
import { Link } from '@spotahome/ui-library';

import './GuaranteeSectionRedesign.scss';

const PUBLIC_ASSETS_LINK =
  'https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/';

const GUARANTEES = [
  {
    title: 'guarantees.support.title',
    text: 'guarantees.support.text',
    icon: 'support-guarantee-icon.svg'
  },
  {
    title: 'guarantees.coverage.title',
    text: 'guarantees.coverage.text',
    icon: 'coverage-guarantee-icon.svg',
    link: { copy: 'guarantees.coverage.link', href: '#our-guarantee' }
  },
  {
    title: 'guarantees.price.title',
    text: 'guarantees.price.text',
    icon: 'price-guarantee-icon.svg'
  },
  {
    title: 'guarantees.online.title',
    text: 'guarantees.online.text',
    icon: 'online-guarantee-icon.svg'
  }
];

const GuaranteeSectionRedesign = () => {
  const guarantees = GUARANTEES.map(({ title, text, link, icon }, i) => {
    const isEven = i % 2 === 0;

    const contentWrapperClassess = classNames(
      'guarantee-section-redesign__content-wrapper',
      {
        'guarantee-section-redesign__guarantee--reverse':
          !isEven && isBiggerThanPhablet()
      }
    );

    return (
      <div className="guarantee-section-redesign__guarantee" key={title}>
        <div className={contentWrapperClassess}>
          <img
            src={`${PUBLIC_ASSETS_LINK}${icon}`}
            width="180"
            alt={trans(title)}
          />
          <div className="guarantee-section-redesign__title">
            {trans(title)}
          </div>
          <div className="guarantee-section-redesign__subtitle">
            <div>{trans(text)}</div>
            {link && (
              <Link underlined href={link.href}>
                {trans(link.copy)}
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  });

  return <div className="guarantee-section-redesign">{guarantees}</div>;
};

export default withInViewEvent(LANDING_SECTIONS.guarantee)(
  GuaranteeSectionRedesign
);
