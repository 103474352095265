/* eslint-disable dot-notation */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import { Modal, Button, ModalHeader, ModalBody, useModalHandle } from '../../';
import { useCookie } from '../utils/withCookie';

import CheckboxWithLabel from '../CheckboxWithLabel';

import OptInIcon from '../icons/OptInIcon';

import styles from './ModalOptIn.module.scss';

const ModalOptIn = ({
  setMarketingOptIn,
  onOpen = () => {},
  onClose = () => {},
  modalBodytext = '',
  checkboxText = '',
  buttonCtaText = ''
}) => {
  const [isOptInAccepted, setOptInAccepted] = useState(false);
  const handleCheckboxChange = value => {
    setOptInAccepted(value);
  };

  const { isModalOpen, handleModalOpen, handleModalClose } = useModalHandle();
  const { getCookie, removeCookie } = useCookie();
  const COOKIE_SIGNED_UP_UP_FROM_SOCIAL = 'sah-ui-social';

  const handleOpen = () => {
    handleModalOpen();
    onOpen();
  };

  const handleClose = () => {
    handleModalClose();
    removeCookie(COOKIE_SIGNED_UP_UP_FROM_SOCIAL);
    onClose();
  };

  const handleConfirm = () => {
    if (isOptInAccepted) {
      setMarketingOptIn();
    }
    handleClose();
    const subscriptionStatus = isOptInAccepted ? 'subscribed' : 'unsubscribed';
  };

  useEffect(() => {
    if (getCookie(COOKIE_SIGNED_UP_UP_FROM_SOCIAL)) {
      handleModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleModalOpen]);

  return (
    <Modal
      contentLabel="modal"
      size="medium"
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      handleModalOpen={handleOpen}
      className={styles['modalOptIn']}
    >
      <ModalHeader
        className={styles.modalOptIn__header}
        onRequestClose={handleClose}
      />
      <ModalBody className={styles['modalOptIn__body']}>
        <div className={styles['modalOptIn__body__image']}>
          <OptInIcon />
        </div>
        <div className={styles['modalOptIn__body__text']}>
          <p>{modalBodytext || trans('opt-in-checkbox-question')}</p>
        </div>
        <div className={styles['modalOptIn__body__checkbox']}>
          <CheckboxWithLabel
            name={'opt-in-checkbox'}
            id="modal-opt-in-checkbox"
            text={checkboxText || trans('opt-in-checkbox-text')}
            callback={handleCheckboxChange}
          />
        </div>
        <div className={styles['modalOptIn__body__buttonContainer']}>
          <Button
            className={styles['modalOptIn__body__buttonContainer-button']}
            id={'optinbutton'}
            onClick={handleConfirm}
            color={isOptInAccepted ? 'primary' : 'secondary'}
          >
            {buttonCtaText || trans('opt-in-button-cta')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

ModalOptIn.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  modalBodytext: PropTypes.string,
  checkboxText: PropTypes.string,
  buttonCtaText: PropTypes.string,
  setMarketingOptIn: PropTypes.func.isRequired
};

export default ModalOptIn;
