import { useSoyuzLocales } from '@spotahome/soyuz/client';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { useLocaleChange } from '../../../hooks/useLocaleChange';
import GlobeIcon from '../icons/GlobeIcon';
import LanguageSwitcher from '../LanguageSwitcher';

import './GlobeLanguageSwitcher.scss';

const GlobeLanguageSwitcher = ({ className = '' }) => {
  const handleOnLocaleChange = useLocaleChange();
  const { current, ui: uiLocales } = useSoyuzLocales();
  const localesToUse = Object.keys(uiLocales);

  const classes = classNames('globe-language-switcher', className);
  return (
    <span className={classes}>
      <GlobeIcon />
      <LanguageSwitcher
        theme="dark"
        onChange={handleOnLocaleChange}
        locales={localesToUse}
        current={current}
      />
    </span>
  );
};

GlobeLanguageSwitcher.propTypes = {
  className: PropTypes.string
};

export default GlobeLanguageSwitcher;
