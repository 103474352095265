import { useMemo, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Mediator } from '@spotahome/soyuz-mediator';

import NoSSR from 'react-no-ssr';

import { useHeaderLinks } from '../../context/ExternalLink';

import MenuIconMobile from '../MenuIconMobile';
import Logo from '../../../base/Logo';
import Modal from '../Modal';

import { COMMON_LOGIN_MODAL } from '../utils/constants';

import Header from './Header/Header';
import HeaderNav from './HeaderNav';
import HeaderNavMobile from './HeaderNav/HeaderNavMobile';

import linkShape from './shapes/link.shape';
import loginShape from './shapes/login.shape';
import themeShape, { DARK_THEME, LIGHT_THEME } from './shapes/theme.shape';

const HeaderContainer = ({
  links = {},
  color = LIGHT_THEME,
  transparent = false,
  locales = { current: 'en' },
  login = {},
  showLogin = false,
  showMobileMenu = true,
  onAppClick = () => {},
  renderLeftContent = () => {},
  renderExtraContent = () => {},
  isContactUsEnabled = false,
  ...restProps
}) => {
  const headerLinks = useHeaderLinks();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(open => !open);
  const closeMenu = () => setMenuOpen(false);
  const { logo: logoLink = {}, ...otherLinks } = links;

  const { backgroundColor, foregroundColor, logoColor } = useMemo(
    () => ({
      backgroundColor: transparent ? 'transparent' : color.background || color,
      foregroundColor:
        color.foreground || color === DARK_THEME ? LIGHT_THEME : DARK_THEME,
      logoColor:
        color.logotype || color === DARK_THEME ? LIGHT_THEME : DARK_THEME
    }),
    [transparent, color]
  );

  useEffect(() => {
    Mediator.subscribe(COMMON_LOGIN_MODAL, closeMenu);
    return () => {
      Mediator.unsubscribe(COMMON_LOGIN_MODAL, closeMenu);
    };
  }, []);

  const handleAppStoreClick = () => onAppClick();

  return (
    <Fragment>
      <Header
        logo={
          <a className="ga-menu-home" href={headerLinks.landing} {...logoLink}>
            <Logo theme={logoColor} size="normal" />
          </a>
        }
        menuIcon={
          !!showMobileMenu && (
            <NoSSR>
              <MenuIconMobile
                color={foregroundColor}
                onMenuClick={toggleMenu}
              />
            </NoSSR>
          )
        }
        {...restProps}
        color={backgroundColor}
        renderLeftContent={renderLeftContent}
        renderExtraContent={renderExtraContent}
      >
        <HeaderNav
          {...otherLinks}
          isTransparent={transparent}
          locales={locales}
          color={foregroundColor}
          login={login}
          showLogin={showLogin}
        />
      </Header>
      {menuOpen && (
        <Modal contentLabel="modal" size="fullscreen" isOpen={menuOpen}>
          <HeaderNavMobile
            {...links}
            onAppClick={handleAppStoreClick}
            locales={locales}
            login={login}
            showLogin={showLogin}
            onClose={toggleMenu}
            isContactUsEnabled={isContactUsEnabled}
          />
        </Modal>
      )}
    </Fragment>
  );
};

HeaderContainer.propTypes = {
  transparent: PropTypes.bool,
  color: PropTypes.oneOfType([
    themeShape,
    PropTypes.shape({
      background: themeShape,
      foreground: themeShape,
      logotype: themeShape
    })
  ]),
  links: PropTypes.shape({
    logo: PropTypes.shape(linkShape)
  }),
  locales: PropTypes.shape({
    current: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
  }),
  login: PropTypes.shape(loginShape),
  showLogin: PropTypes.bool,
  showMobileMenu: PropTypes.bool,
  onAppClick: PropTypes.func,
  renderLeftContent: PropTypes.func,
  renderExtraContent: PropTypes.func,
  isContactUsEnabled: PropTypes.bool
};

export default HeaderContainer;
