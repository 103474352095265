import PropTypes from 'prop-types';

import { Button, useCitiesContext } from '@spotahome/ui-library';
import OurGuarantee from '@spotahome/ui-library/src/tenant/components/OurGuarantee';
import FAQSection from '@spotahome/ui-library/src/tenant/components/FAQSection';

import PropertyTypeSection from '@spotahome/landings-common/src/components/PropertyTypeSection';
import CitiesShowcase from '@spotahome/landings-common/src/components/CitiesShowcase';
import Footer from '@spotahome/landings-common/src/components/Footer';
import TestimonialsCarousel from '@spotahome/landings-common/src/components/TestimonialsCarousel/TestimonialsCarousel';

import { getTenantFaq } from '@spotahome/landings-common/src/utils/faq';

import FindYourHome from '@spotahome/ui-library/src/tenant/components/FindYourHome';

import { trans } from '@spotahome/soyuz/client';

import MagnifyingGlassIcon from '@spotahome/landings-common/src/components/MagnifyingGlassIcon';

import React from 'react';

import HeroRedesign from './HeroRedesign';
import GuaranteeSectionRedesign from './GuaranteeSectionRedesign';
import LandlordSection from './LandlordSection';

const ContentRedesign = ({ deviceType, isPartner }) => {
  const { cities } = useCitiesContext();
  return (
    <div>
      <HeroRedesign cityList={cities} deviceType={deviceType} />
      {!isPartner && (
        <>
          <GuaranteeSectionRedesign />
          <TestimonialsCarousel />
          <OurGuarantee />
          <PropertyTypeSection deviceType={deviceType} isRedesign />
          <LandlordSection />
          <CitiesShowcase
            cityList={cities}
            deviceType={deviceType}
            isRedesign
          />
          <FAQSection questions={getTenantFaq()} isRedesign />
          <FindYourHome />
          <Footer />
        </>
      )}
    </div>
  );
};

ContentRedesign.propTypes = {
  deviceType: PropTypes.string.isRequired,
  isPartner: PropTypes.bool.isRequired
};

export default ContentRedesign;
