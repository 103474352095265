import { Guarantees, Section } from '@spotahome/ui-library';
import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import './GuaranteeSection.scss';

const GuaranteeSection = () => {
  return (
    <section className="guarantee-section">
      <Section background="light">
        <Guarantees />
      </Section>
    </section>
  );
};

export default withInViewEvent(LANDING_SECTIONS.guarantee)(GuaranteeSection);
