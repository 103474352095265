import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const LandlordFooter = props => {
  const landlordLinks = [
    {
      url: props.publishYourProperty,
      transKey: 'footer.item.publish_your_property'
    }
  ];

  return (
    <Fragment>
      <FooterBlockTitle text={trans('footer.label.landlords')} />
      <FooterBlockList>
        {landlordLinks.map(({ transKey, url }) => (
          <FooterLink key={transKey} linkTheme={props.linkTheme} href={url}>
            {trans(transKey)}
          </FooterLink>
        ))}
      </FooterBlockList>
    </Fragment>
  );
};

LandlordFooter.propTypes = {
  publishYourProperty: PropTypes.string.isRequired,
  linkTheme: PropTypes.string.isRequired
};

export default LandlordFooter;
