import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import { intersectionWith } from 'lodash';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const JoinUsFooter = props => {
  const joinUsLinks = [
    {
      url: props.collaborators,
      transKey: 'footer.item.collaborators'
    },
    {
      url: props.studentAmbassador,
      transKey: 'footer.item.studentAmbassador'
    },
    {
      url: props.becomePartner,
      transKey: 'footer.item.become_partner'
    },
    {
      url: props.workWithUs,
      transKey: 'footer.item.work_with_us'
    },
    {
      url: props.internships,
      transKey: 'footer.item.internships'
    }
  ];

  return (
    <FooterBlock>
      <FooterBlockTitle text={trans('footer.join-us')} />
      <FooterBlockList>
        {joinUsLinks.map(({ transKey, url }) => (
          <FooterLink key={transKey} linkTheme={props.linkTheme} href={url}>
            {trans(transKey)}
          </FooterLink>
        ))}
      </FooterBlockList>
    </FooterBlock>
  );
};

JoinUsFooter.propTypes = {
  becomePartner: PropTypes.string.isRequired,
  collaborators: PropTypes.string.isRequired,
  workWithUs: PropTypes.string.isRequired,
  internships: PropTypes.string.isRequired,
  studentAmbassador: PropTypes.string.isRequired,
  linkTheme: PropTypes.string.isRequired
};

export default JoinUsFooter;
