import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterLink from './components/FooterLink';

import HelpCenterButton from './HelpCenterButton';
import FooterBlockList from './components/FooterBlockList';

const HelpFooter = props => {
  const { contactUs, linkTheme } = props;

  return (
    <FooterBlock>
      <FooterBlockTitle text={trans('footer.label.support')} />

      <HelpCenterButton color="primary" href={contactUs} />
      <FooterBlockList>
        <FooterLink
          key="footer.item.contact_support"
          href={contactUs}
          linkTheme={linkTheme}
        >
          {trans('footer.item.contact_support')}
        </FooterLink>
      </FooterBlockList>
    </FooterBlock>
  );
};

HelpFooter.propTypes = {
  contactUs: PropTypes.string.isRequired,
  locales: PropTypes.shape({
    current: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  linkTheme: PropTypes.string.isRequired
};

export default HelpFooter;
